import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { colors } from "../styles/colors";
import Button from "./button";
import { bp } from "../styles/colors";
import Img from "gatsby-image";
import { SmallTitle } from "../styles/fonts";
import { MediumTitle } from "../styles/fonts";

const ProjectPopup = (props) => {
    const [bigImage, setBigImage] = useState();
    const [images, setImages] = useState([]);
    const [activeImage, setActive] = useState();

    const changeImage = (img) => {
        setBigImage(img.childImageSharp.fluid);
        setActive(img.childImageSharp.id);
        document.getElementById("projectPopup").scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        if (props.images.length !== 0) {
            setImages(props.images);
            setBigImage(props.images[0].childImageSharp.fluid);
            setActive(props.images[0].childImageSharp.id);
        }
    }, [images]);

    const contactUs = () => {
        props.onClick();

        setTimeout(() => {
            const contactSection = document.getElementById("contactSection");
            contactSection.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }, 100);
    };

    return (
        <PopContainer
            className="projectPopup"
            id="projectPopup"
            key={props.id}
            initial={{ opacity: 0, scale: 0.2 }}
            animate={{
                x: 0,
                opacity: 1,
                scale: 1,
                transition: { duration: 0.7, type: "spring", bounce: 0.38 },
            }}
            exit={{
                opacity: 0,

                transition: { duration: 0.5 },
            }}
        >
            <PopWrapper>
                <PopRow column>
                    <SmallTitle style={{ marginBottom: "1rem" }}>
                        {props.smallTitle}
                    </SmallTitle>
                    <MediumTitle style={{ marginBottom: "1rem" }}>
                        {props.medTitle}
                    </MediumTitle>
                    <SmallDesc>{props.smallDesc}</SmallDesc>
                </PopRow>

                <PopRow>
                    <BigImgContainer id="bigImg">
                        <Img fluid={bigImage} />
                    </BigImgContainer>
                    <DescContainer>
                        <LocText>{props.location}</LocText>
                        <DescList>
                            {props.listElements.map((n, index) => (
                                <DescListElem key={index}>
                                    {n.listElement}
                                </DescListElem>
                            ))}
                        </DescList>
                        <DescText>{props.description}</DescText>
                        {props.buttonText === "vuokraus" ? (
                            <>
                                <RentText>
                                    Kysy saatavuus ottamalla yhteyttä
                                </RentText>

                                <Button onClick={contactUs}>
                                    Ota yhteyttä
                                </Button>
                            </>
                        ) : (
                            <Button onClick={contactUs}>Ota yhteyttä</Button>
                        )}
                    </DescContainer>
                </PopRow>

                {images.length > 1 && (
                    <ImgRow space>
                        {images.map((n, index) => (
                            <ActiveImageDiv key={index}>
                                <SmallImgContainer
                                    onClick={() => changeImage(n)}
                                >
                                    <SmallImgHover
                                        fluid={n.childImageSharp.fluid}
                                    ></SmallImgHover>
                                </SmallImgContainer>
                                <ActiveBlock
                                    active={
                                        activeImage === n.childImageSharp.id
                                            ? "true"
                                            : "false"
                                    }
                                ></ActiveBlock>
                            </ActiveImageDiv>
                        ))}
                    </ImgRow>
                )}
            </PopWrapper>
            <CloseButton onClick={props.onClick}>
                <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    length="auto"
                    viewBox="0 0 50 50"
                >
                    <Line
                        className="st0"
                        x1="10"
                        y1="40"
                        x2="40"
                        y2="10"
                        stroke="white"
                        stroke-width="4px"
                    />
                    <Line
                        className="st1"
                        x1="40"
                        y1="40"
                        x2="10"
                        y2="10"
                        stroke="white"
                        stroke-width="4px"
                    />
                </svg>
            </CloseButton>
        </PopContainer>
    );
};

const RentText = styled.p`
    color: ${colors.grey2};
    margin-bottom: 1rem;
    line-height: 135%;
    font-size: 1.1rem;
    font-weight: 700;
`;

const BigImgContainer = styled.div`
    flex: 70%;
    margin: 0 3rem 3rem 0;
    width: 70%;
    //height: 400px; tää muuttaa kuvasuhdetta tällee
    object-fit: contain;
    overflow: hidden;

    @media screen and (max-width: ${bp.mobile}) {
        flex: auto;
        width: 100%;
        margin: 3rem auto;
    }

    @media screen and (max-width: 500px) {
        margin: 0 0 2rem 0;
        height: 100%;
    }
`;

const ActiveBlock = styled.div`
    width: 100%;
    height: 0.7rem;
    background-color: ${(props) =>
        props.active === "true" ? colors.black : "transparent"};
    transition: 0.3s;

    @media screen and (max-width: ${bp.mobile}) {
        display: none;
    }
`;

const ActiveImageDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;
    gap: 0.7rem;
    cursor: pointer;

    -webkit-tap-highlight-color: transparent;

    &:hover {
        ${ActiveBlock} {
            background-color: ${colors.black};
        }
    }

    @media screen and (max-width: ${bp.mobile}) {
        width: 100%;
    }
`;

const SmallImgHover = styled(Img)`
    background-color: black;
    transition: 0.7s all;
    width: 100%;

    -webkit-tap-highlight-color: transparent;

    &:hover {
        display: flex;
        ${ActiveBlock} {
            background-color: ${colors.grey5};
        }
    }
`;

const SmallImgContainer = styled.div`
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    overflow: hidden;

    -webkit-tap-highlight-color: transparent;

    transition: all 0.15s;
    cursor: pointer;

    &:hover {
        ${SmallImgHover} {
            transform: scale(1.05);
        }
        ${ActiveBlock} {
            background-color: black;
        }
    }

    @media screen and (max-width: ${bp.mobile}) {
        width: 100%;

        &:hover {
            ${SmallImgHover} {
                transform: none;
            }
        }
    }

    @media screen and (max-width: ${bp.mobileSmall}) {
    }
`;
const Line = styled.line`
    background-color: white;
    color: white;
    transform-origin: center;
    stroke-width: 4px;
    transition: transform ease 0.2s;
`;
const CloseButton = styled.div`
    width: 2rem;
    height: 2rem;
    position: fixed;
    top: 5%;
    right: 10%;
    cursor: pointer;
    background: ${colors.black};
    padding: 1rem;

    -webkit-tap-highlight-color: transparent;
    transition: all 0.2s;
    border-radius: 50%;

    &:hover {
        .st0 {
            transform: rotate(45deg);
        }
        .st1 {
            transform: rotate(-45deg);
        }
    }

    @media screen and (max-width: ${bp.mobile}) {
        width: 2rem;
        height: 2rem;
        top: 1rem;
        right: 1rem;
        position: fixed;
    }
`;

const ImgRow = styled.div`
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: ${(props) => (props.space ? "space-between" : "center")};
    align-items: flex-start;
    flex-direction: ${(props) => (props.column ? "column" : "row")};

    @media screen and (max-width: ${bp.mobile}) {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }
`;

const DescText = styled.p`
    line-height: 135%;
    font-size: 1.1rem;
    color: ${colors.black};
    margin: 1rem 0 2rem 0;
`;

const DescListElem = styled.li`
    color: ${colors.grey4};
    line-height: 135%;
    font-size: 1.1rem;
    margin: 0.3rem 0;
`;

const DescList = styled.ul`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 1.5rem;
`;

const LocText = styled.p`
    font-size: 2rem;
    color: ${colors.black};
    font-weight: 900;
    margin-bottom: 1rem;

    @media screen and (max-width: ${bp.mobile}) {
        font-size: 1.5rem;
    }
`;

const DescContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 30%;
`;

const SmallDesc = styled.p`
    font-size: 1.1rem;
    width: 50%;
    color: ${colors.black};
    margin-bottom: 3rem;
`;

const PopRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: ${(props) => (props.space ? "space-between" : "center")};
    align-items: flex-start;
    flex-direction: ${(props) => (props.column ? "column" : "row")};

    @media screen and (max-width: ${bp.mobile}) {
        flex-direction: column;
    }
`;

const PopWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    position: absolute;
    top: 0rem;
    padding: 6rem 0 5rem 0;
    max-width: 868px;
    transition: 0.5s;
`;

const PopContainer = styled.div`
    position: fixed;
    overflow-x: hidden;
    overflow-y: auto;
    top: 0%;
    left: 0%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    z-index: 10000;
    height: 100%;
    margin-bottom: 5rem;

    @media screen and (max-width: ${bp.mobile}) {
        height: 100%;
    }
`;

export default ProjectPopup;
